import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header';
import FurnitureItem from '../../components/FurnitureItem/FurnitureItem';
import $ from 'jquery';
import '../../styles.css';
import './FurniturePage.css';

const FurniturePage = () => {

    const location = useLocation();
    const href = location.pathname;
    const [selectedItem, setSelectedItem] = useState(null); // State to store the selected item
    const [error, setError] = useState(null); // State to manage errors


    useEffect(() => {
        //fetch data from the php file using ajax
        const fetchItemByHref = () => {
            $.ajax({
                url: `/php/getItemByHref.php?href=${encodeURIComponent(href)}`,
                method: 'GET',
                dataType: 'json',
                success: (data) => {
                    if (data.message) {
                        setError(data.message); //set error message if any
                    } else {
                        setSelectedItem(data); //set the item in state
                    }
                },
                error: (xhr, status, error) => {
                    setError('An error occured: ', + error);
                }
            });
        };

        fetchItemByHref(); //call the fetch function
    }, [href]); //dependency array includes href to fetch on change
    
    if (error) {
        return <div>Error: {error}</div>; //error handling
    }

    return (
        <div className='wrapper'>
            <Header />
            <main>
                {selectedItem && <FurnitureItem product={selectedItem} />} {/* render item if available */}
            </main>
            
        </div>
    )
}; 

export default FurniturePage;