import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './Gallery.css';




const Gallery = () => {

    
    const [furnitureItems, setFurnitureItems] = useState([]);
    const [galleryOrder, setGalleryOrder] = useState([]);

    //scroll to top when component mounts
    useEffect(() => {
        window.scrollTo(0,0);

        //fetch furniture items
        $.ajax({
            url: '/php/getAllFurniture.php',
            method: 'GET',
            dataType: 'json',
            success: function(data) {
                setFurnitureItems(data);
            },
            error: function(xhr, status, error) {
                console.error('Error fetching furniture items ', error);
            }
        });

        //fetch gallery order
        $.ajax({
            url: '/php/getGalleryOrder.php',
            method: 'GET',
            dataType: 'json',
            success: function(data) {
                setGalleryOrder(data);
            },
            error: function(xhr, status, error) {
                console.error('Error fetching gallery data ', error);
            }
        });

    }, []);


       // Wait for both furnitureItems and galleryOrder to be fetched before rendering
    if (furnitureItems.length === 0 || galleryOrder.length === 0) {
        return <div>Loading...</div>; // Show a loading state until data is fetched
    }
    
    // Create an array from gallery_order object
    const orderedItems = galleryOrder.map((order) => {
        const { position, item_id } = order;
        if (item_id === null) { //handle null for X / empty spaces
            return (
                <div key={position} className="gallery-item">
                    <img className="rectangle-x" src={`${process.env.PUBLIC_URL}/images/rectangle-x.svg`} alt="Close" />
                </div>
            );
        } else {
            const item = furnitureItems.find(item => item.id === item_id);
            if (!item) return null; // In case there's no item with the given id
            
            return (
                <div key={item.id} className="gallery-item">
                    <div className='gallery-img-container'>
                        <Link to={item.href}>
                            <img src={`${process.env.PUBLIC_URL}${item.imageSrc}`} alt={item.name}/>
                        </Link>
                    </div>
                    <div className='gallery-item-desc'>
                        <span className='gallery-item-name'>{item.name}</span>
                        <span className='gallery-item-price'>{item.price}</span>
                    </div>
                </div>
            );
        }
    });

    return (
        <div className='gallery'>
            {orderedItems}
        </div>
    );
}

export default Gallery;