import React from "react";
import './About.css';

const About = () => {
    return (
        <div id="about">
            <h1>o nas</h1>
            <p>
            Lorem Ipsum Dolor Sit Consectetur Adipiscing Elit Sed Euismod Leo Id Feugiat Sagittis Turpis Velit Vehicula Justo Eget Fermentum Tortor Mi Eu Libero Duis Nec Justo Id Odio Sollicitudin Vestibulum Donec Vel Justo Quis Justo Condimentum Pellentesque Vel At Nunc In Vitae Felis Quis Eros Efficitur Aliquet Duis Dictum Libero Nec Sapien Tempus Vitae Accumsan Nulla Hendrerit Nulla Facilisi Nullam Rutrum Purus Sed Lacus Gravida Sit Amet Fermentum Neque Pellentesque Vestibulum Ante Ipsum Primis In Faucibus Orci Luctus Et Ultrices Posuere Cubilia Curae Mauris Euismod Arcu At Ipsum Rhoncus Nec Finibus Enim Tristique Curabitur Auctor Consequat Ante Vitae Tincidunt Nullam Euismod Elit Id Elit Lacinia Convallis.
            </p>
        </div>
    )
}

export default About;