import React, { useEffect } from 'react';
import Header from '../../components/Header/Header';
import Quote from '../../components/Quote/Quote';
import Slider from '../../components/Slider/Slider';
import About from '../../components/About/About';
import Contact from '../../components/Contact/Contact';
import Quote2 from '../../components/Quote2/Quote2';
import '../../styles.css';

const HomePage = () => {

    //scroll to top when component mounts
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    


    return (
        <div>
            <Header />
            <main>
                <Quote />
                <Slider />
                <About />
                <Contact />
                <Quote2 />
            </main>
            
        </div>
    )
}; 

export default HomePage;