import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../images/olmologo2.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import ContactModal from '../ContactModal/ContactModal';
import NavBar from '../NavBar/NavBar';
import './Header.css';

const Header = () => {
    const [showNavModal, setShowNavModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);

    const openNavModal = () => {
        setShowNavModal(true);
        $('#nav-bar').addClass('visible');
    }

    const closeNavModal = () => {

        $('#nav-bar').removeClass('visible');

        setTimeout(() => {
            setShowNavModal(false);
        }, 800);
    }

    const openContactModal = () => {
        closeNavModal();
        setShowContactModal(true);
    }

    const closeContactModal = () => {
        $('#contact-modal').removeClass('visible');

        setTimeout(() => {
            setShowContactModal(false);
        }, 600);
    }


    const navigate = useNavigate();

    const scrollToGallery = () => {
        // Programmatically navigate to the homepage
        navigate(`/`);

        // Wait for a short delay before scrolling to ensure the navigation completes
        setTimeout(() => {
        // Use jQuery to scroll to the gallery section
        $('html, body').animate({
            scrollTop: $('#gallery').offset().top
        }, 1000); // Adjust the duration of the animation as needed
        }, 100); // Adjust the delay as needed
    };

    return ( 
        <header>
            <div className='flex-container-header'>
                
                <div id='logo-container'>
                    <Link to={`/`} ><img src={logo} alt="logo" /></Link>
                </div>
                <div id='nav-icon-container' onClick={openNavModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="8" viewBox="0 0 27 8">
                        <g id="Menu" transform="translate(0)">
                            <rect id="Rectangle_4" data-name="Rectangle 4" width="27" height="2" rx="1" transform="translate(0)" fill="#141314"/>
                            <rect id="Rectangle_5" data-name="Rectangle 5" width="27" height="2" rx="1" transform="translate(0 6)" fill="#141314"/>
                        </g>
                    </svg>
                    
                    {showContactModal && <ContactModal closeContactModal={closeContactModal}/>}
                </div>
            </div>
            {showNavModal && <NavBar scrollToGallery={scrollToGallery} closeModal={closeNavModal} openContactModal={openContactModal}/>}
        </header>
    );
};

export default Header;