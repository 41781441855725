import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import './ContactModal.css';

const ContactModal = ({ closeContactModal }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Send form data to server-side script (e.g., PHP emailer script)
        console.log(formData); // For testing
    };

   
    useEffect(() => {
        setTimeout(() => {
            $('#contact-modal').addClass('visible');
        }, 100);

        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeContactModal();
            }
        }
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup: remove event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [closeContactModal]);

    return (
        <div id="contact-modal">
            <div id='closeBtnContactModal' >
                        {/* <img className="rectangle-x" onClick={closeContactModal} src={`${process.env.PUBLIC_URL}/images/rectangle-x.svg`} alt="Close" /> */}
                        <svg height='60px' width='60px' onClick={closeContactModal}  xmlns="http://www.w3.org/2000/svg" alt='close button'>
                                <rect x="0" y="0" width="100%" height="100%" stroke="#A9B1AA" strokeWidth="2" fill="none"/>
                                <line x1="0" y1="0" x2="100%" y2="100%" stroke="#A9B1AA" strokeWidth="1"/>
                                <line x1="100%" y1="0" x2="0" y2="100%" stroke="#A9B1AA" strokeWidth="1"/>
                        </svg>
            </div>
            
            <h1>kontakt</h1>
            <p>Chcesz się skontaktować z nami? Nie wahaj się i napisz lub zadzwoń! Jesteśmy dostępni, aby odpowiedzieć na Twoje pytania, rozwiać wątpliwości lub omówić szczegóły projektu.</p>

            <div className="contact-info">
                <span>Adres:</span>
                <span><b>ul. Przykładowa 123, 00-000 Miasto, Kraj</b></span>
            </div>

            <div className="contact-info">
                <span>Telefon:</span>
                <span><b>+48 123 456 789</b></span>
            </div>
                    
            <div className="contact-info">
                <span>E-mail:</span>
                <span><b>info@olmo.pl</b></span>
            </div>

            <p id="zapytanie">wyślij swoje zapytanie</p>

            <form id='contact-form' onSubmit={handleSubmit}>
                    <div id='form-row'>
                        <input
                            id='form-name'
                            type='text'
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                            placeholder='IMIĘ I NAZWISKO'
                            required
                        />
                        <input
                            id="form-email"
                            type='email'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            placeholder='EMAIL'
                            required
                        />
                    </div>
                    <textarea
                        type='text'
                        name='message'
                        value={formData.message}
                        onChange={handleChange}
                        placeholder='WIADOMOŚĆ'
                        required
                    ></textarea>
                    <button type="submit">WYŚLIJ</button>
                </form>
        </div>
    );
}

export default ContactModal;