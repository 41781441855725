import React from "react";
import './GalleryHeader.css';

const GalleryHeader = () => {
    return (
        <div id="gallery-header">
            <h1>nasze meble</h1>
        </div>
    )
};

export default GalleryHeader;