// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import FurniturePage from './pages/FurniturePage/FurniturePage';
import GalleryPage from './pages/GalleryPage/GalleryPage';
const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path='/' element={<HomePage />} />
          <Route exact path='/produkty/:furnitureID' element={<FurniturePage />} />
          <Route exact path='/produkty' element={<GalleryPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
