import React from 'react';
import Gallery from '../../components/Gallery/Gallery';
import GalleryHeader from '../../components/GalleryHeader/GalleyHeader';
import Header from '../../components/Header/Header';
import '../../styles.css';


const GalleryPage = () => {
    return (
        <div>
            <Header />
            <main>
                <GalleryHeader />
                <Gallery />
            </main>
            
        </div>
    )
}; 

export default GalleryPage;