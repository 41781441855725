import React from "react";
import './Quote.css';

const Quote = () => {
    return (
        <div id="quote">
            <div id="quote-text">
                <h1>Twórz przestrzeń,</h1>
                <h1><b>w której</b></h1>
                <h1><b>kochasz żyć</b></h1>
            </div>
            
        </div>
    )
}

export default Quote;