import React from "react";
import logo from '../../images/olmologo2.svg';
import './Quote2.css';


const Quote2 = () => {
    return (
        <div id="quote2">
            
            <div id="quote2-content">
                <h1 id='first-line'>a matter of craftsman</h1>
                <h1 id='second-line'>artisanal/dissent/cozy</h1>
                
            </div>
            <img src={logo} height='60px' alt="logo"/>
        </div>
    )
}

export default Quote2;