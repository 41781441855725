import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './Slider.css'; 

const Slider = () => {

    const [furnitureItems, setFurnitureItems] = useState([]);

    useEffect(() => {
        $.ajax({
            url: '/php/getAllFurniture.php',
            type: 'GET',
            dataType: 'json',
            success: function (data) {
                setFurnitureItems(data);
            },
            error: function (xhr, status, error) {
                console.error('~Error fetching furniture items: ', error);
            }
        });
    }, []);

    


    return (
        <div className="slider">
        <Link to='/produkty' className="nav-link-meble">nasze produkty</Link>
        <div className="slide-track">
            {furnitureItems.map((item) => (
                <div className="slide" key={item.id}>
                    <Link to='/produkty'>
                        <img src={`${process.env.PUBLIC_URL}${item.imageSrc}`} alt={item.name} />
                    </Link>
                </div>
            ))}
            {furnitureItems.map((item) => (
                <div className="slide" key={item.id}>
                    <Link to='/produkty'>
                        <img src={`${process.env.PUBLIC_URL}${item.imageSrc}`} alt={item.name} />
                    </Link>
                </div>
            ))}
        </div>
        </div>
    );
};

export default Slider;
